<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-08-01 15:39:03
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-08-01 15:49:05
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\ceshi\ces.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <Lunbo/>

  </div>
</template>

<script>
import Lunbo from '@/components/Lunbo/index'



export default {
  name: 'App',
  components: {
    Lunbo,


  },
  
};
</script>

<style >
#app{
  /* width: 100%; */
}
</style>
